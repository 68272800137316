
export interface CommunicatorStateModel {
    name: string;
    isOnline: boolean;
    messages: MessageModel[];
}

export interface GuardianCoordinatorConfigModel {
    variableUpdateInterval: string;
    variableUpdateTimeout: string;
    coordinationPeriod: string;
    rfmCommunicator?: CommunicatorRfmConfigModel;
    hueCommunicator?: CommunicatorHueConfigModel;
    udpCommunicator?: CommunicatorUdpConfigModel;
    modbusCommunicator?: CommunicatorModbusConfigModel;
    mqttCommunicator?: CommunicatorMqttConfigModel;
    mail?: MailConfigModel;
}

export interface CommunicatorRfmConfigModel {
    portName: string;
    baudRate: number;
    controllerOpenRetryDelay: string;
}

export interface CommunicatorHueConfigModel {
    ipAddress: string;
    apiKey: string;
    clientOpenRetryDelay: string;
}

export interface ModbusRegister {
    name: string;
    address: number;
    type: string;
    kind: string;
}

export interface CommunicatorModbusConfigModel {
    ipAddress: string;
    clientOpenRetryDelay: string;
    registers: ModbusRegister[];
}

export interface MqttTopicVariableValue {
    text: string;
    value: number;
}

export interface MqttTopicVariable {
    name: string;
    jsonPath: string;
    type: string;
    kind: string;
    values: MqttTopicVariableValue[];
    doStoreValue: boolean;
}

export interface MqttTopic {
    name: string;
    topic: string;
    kind: string;
    variables: MqttTopicVariable[];
}

export interface CommunicatorMqttConfigModel {
    broker: string;
    brokerPort: number;
    clientOpenRetryDelay: string;
    topics: MqttTopic[];
}

export interface CommunicatorUdpConfigModel {
    ipAddress: string;
    port: number;
}

export interface MailConfigModel {
    mailServer: string;
    mailPort: number;
    mailUserName: string;
    mailPassword: string;
    mailSender: string;
    mailRecipients: string[];
}

export interface GuardianModel {
    id: number;
    name: string;
    group: string;
    title: string;
    communicatorName: string;
    state: string;
    health: string;
    variables: VariableModel[];
}

export interface GuardianGroupModel {
    name: string;
    guardians: GuardianModel[];
}

export interface VariableModel {
    name: string;
    type: string;
    kind: string;
    value: string | number | boolean;
    values: VariableValueModel[];
    isReadOnly: boolean;
    lastUpdate: string;
    ageState: VariableAgeState;
}

export enum VariableAgeState {
    Unknown = "Unknown",
    Missing = "Missing",
    UpToDate = "UpToDate",
    Stale = "Stale"
}

export interface VariableValueModel {
    value: string;
    title: string;
}

export interface MessageModel {
    time: string;
    level: MessageLogLevel;
    id: string;
    text: string;
}

export enum MessageLogLevel {
    Trace = "Trace",
    Debug = "Debug",
    Information = "Information",
    Warning = "Warning",
    Error = "Error",
    Critical = "Critical",
    None = "None"
}

export interface GuardianConfigModel {
    id: number;
    name: string;
    group: string;
    isEnabled: boolean;
    variableUpdateInterval: string;
    doUpdatePeriodically: boolean;
    identifyOnReceive: boolean;
    dummyAddress: number;
    rfmAddress: number;
    udpAddress: number;
    hueAddress: string;
    baosId: number;
}

export interface CommonConfigModel {
    messages: MessageModel[];
}

export interface StateMachineStatus {
    id: number;
    name: string;
    isRunning: boolean;
    isEnabled: boolean;
    isValid: boolean;
    currentState: string;
    rating: string;
    timeStateEntry: Date;
    timeInState: number;
    messages: MessageModel[];
    clicks: TriggerClick[];
    stateNames: string[];
}

export interface TriggerClick {
    name: string;
    title: string;
    isClicked: boolean;
}

export interface StateMachineConfigModel extends CommonConfigModel {
    id: number;
    name: string;
    isRunning: boolean;
    states: StateMachineStateConfigModel[];
    variables: StateMachineVariableConfig[];
}

export class StateMachineVariableType {
    static readonly GuardianVariable: string = "GuardianVariable";
    static readonly Constant: string = "Constant";
    static readonly StateMachineState: string = "StateMachineState";
}

export class StateMachineValueType {
    static readonly Number: string = "Number";
    static readonly String: string = "String";
    static readonly Enum: string = "Enum";
}

export interface StateMachineVariableConfig {
    name: string;
    type: string;
    valueType: string;
    guardianId: number;
    variableName: string;
    constant: string;
    stateMachineId: number;
    values: VariableValueModel[];
}

export interface StateMachineStateConfigModel extends CommonConfigModel {
    name: string;
    rating: string;
    triggers: TriggerConfigModel[];
    entryActions: TriggerActionConfigModel[];
    exitActions: TriggerActionConfigModel[];
}

export interface TriggerConfigModel extends CommonConfigModel {
    name: string;
    conditions: TriggerConditionConfigModel[];
    actions: TriggerActionConfigModel[];
}

export class TriggerConditionKind {
    static readonly None: string = "None";
    static readonly VariableToValue: string = "VariableToValue";
    static readonly VariableAgeToValue: string = "VariableAgeToValue";
    static readonly VariableToVariable: string = "VariableToVariable";
    static readonly TimeInState: string = "TimeInState";
    static readonly TimeOfDay: string = "TimeOfDay";
    static readonly TimeOfYear: string = "TimeOfYear";
    static readonly DayOfWeek: string = "DayOfWeek";
    static readonly DayOfMonth: string = "DayOfMonth";
    static readonly DayOfYear: string = "DayOfYear";
    static readonly Click: string = "Click";
}

export interface TriggerConditionConfigModel extends CommonConfigModel {
    kind: string;
    operandLeft: string;
    operator: string;
    operandRight: string;
}

export interface TriggerActionConfigModel extends CommonConfigModel {
    kind: string;
    values: { [name: string]: string };
}

export enum CoordinatorUpdateMode {
    Full = "Full",
    Partial = "Partial",
}

export interface MeasurementSeries {
    name: string;
    tags: any;
    values: any[][];
}

export class UiVariableModel {

    protected variable: VariableModel;
    protected switchHandler: (variable: VariableModel, variableValue: VariableValueModel) => void;

    get name(): string {
        return this.variable.name;
    }

    get state(): any {
        return this.variable.value;
    }

    get isReadOnly(): boolean {
        return this.variable.isReadOnly;
    }

    get lastUpdate(): string {
        return this.variable.lastUpdate;
    }

    get kind(): VariableKind {
        return this.variable.kind;
    }

    get ageState(): VariableAgeState {
        return this.variable.ageState;
    }

    get values(): VariableValueModel[] {
        return this.variable.values;
    }

    constructor(variable: VariableModel, switchHandler: (variable: VariableModel, variableValue: VariableValueModel) => void) {
        this.variable = variable;
        this.switchHandler = switchHandler;
    }

    setValue(index: number): void {
        if (this.switchHandler && this.values && this.values.length > index) {
            this.switchHandler(this.variable, this.variable.values[index]);
        }
    }

    getTitle(index: number): string {
        if (this.values && this.values.length > index) {
            return this.values[index].title;
        } else {
            return "";
        }
    }
}

export class BooleanVariableModel extends UiVariableModel {

    private _state: boolean;
    protected variableValue: VariableValueModel;

    get state(): any {
        return this._state;
    }

    set state(newState: any) {
        if (newState !== this._state) {
            this._state = newState;
            this.variableValue = this.variable.values[this._state ? 1 : 0];
            if (this.switchHandler) {
                this.switchHandler(this.variable, this.variableValue);
            }
        }
    }

    get stateTitle(): string {
        return this.variableValue.title;
    }

    constructor(variable: VariableModel, switchHandler: (variable: VariableModel, variableValue: VariableValueModel) => void) {
        super(variable, switchHandler);
        this._state = variable.value == variable.values[1].value;
        this.variableValue = variable.values[this._state ? 1 : 0];
    }
}

export class EnumVariableModel extends UiVariableModel {

    private variableValue: VariableValueModel;
    private _state: string;

    get state(): string {
        return this._state;
    }

    set state(newState: string) {
        if (newState !== this._state) {
            this._state = newState;
            this.variableValue = this.variable.values.filter(v => v.value === newState)[0];
            if (this.switchHandler) {
                this.switchHandler(this.variable, this.variableValue);
            }
        }
    }

    get stateTitle(): string {
        return this.variableValue.title;
    }

    get values(): VariableValueModel[] {
        return this.variable.values;
    }

    constructor(variable: VariableModel, switchHandler: (variable: VariableModel, variableValue: VariableValueModel) => void) {
        super(variable, switchHandler);
        if (variable.value) {
            this.variableValue = variable.values.filter(v => v.value === variable.value.toString())[0];
        } else {
            this.variableValue = variable.values[0];
        }
        this._state = this.variableValue.value;
    }
}

export class ColorVariableModel {

    private _state: string;
    protected variable: VariableModel;
    protected changeHandler: (variable: VariableModel, value: string) => void;

    get name(): string {
        return this.variable.name;
    }

    get isReadOnly(): boolean {
        return this.variable.isReadOnly;
    }

    get state(): string {
        return this._state;
    }

    set state(newState: string) {
        if (newState !== this._state) {
            this._state = newState;
            this.changeHandler(this.variable, newState.substring(1));
        }
    }

    get stateTitle(): string {
        return this._state;
    }

    constructor(variable: VariableModel, changeHandler: (variable: VariableModel, value: string) => void) {
        this.variable = variable;
        this.changeHandler = changeHandler;
        if (this.variable.value) {
            if (typeof (this.variable.value) === "number") {
                this._state = "#" + this.variable.value.toString(16);
            } else {
                this._state = "#FFFFFF";
            }
        } else {
            this._state = "#FFFFFF";
        }
    }
}

export class VariableKind {
    static readonly Voltage: string = "Voltage";
    static readonly DisplayDimming: string = "DisplayDimming";
    static readonly Humidity: string = "Humidity";
    static readonly Temperature: string = "Temperature";
    static readonly Pressure: string = "Pressure";
    static readonly WaterContent: string = "WaterContent";
    static readonly Percentage: string = "Percentage";
    static readonly Illuminance: string = "Illuminance";
    static readonly Distance: string = "Distance";
    static readonly Relay: string = "Relay";
    static readonly Switch: string = "Switch";
    static readonly MoveUpDown: string = "MoveUpDown";
    static readonly StepUpDown: string = "StepUpDown";
    static readonly ShutterState: string = "ShutterState";
    static readonly Power: string = "Power";
    static readonly Energy: string = "Energy";
    static readonly Color: string = "Color";
}

export class VariableType {
    static readonly Byte: string = "Byte";
    static readonly Single: string = "Single";
    static readonly Color: string = "Color";
}

export class ControlModel {
    id: number = -1;
    type: ControlType = ControlType.Light;
    name: string = "";
    isValid: boolean = false;
}

export class ControlPositionModel {
    id: number = -1;
    controlId: number = -1;
    type: ControlType = ControlType.Light;
    name: string = "";
    isValid: boolean = false;
    isValueValid: boolean = false;
    isUp: boolean = false;
    isDown: boolean = false;
    value: number = 0;
    unit: string = "";
    precision: number = 1;
    x: number = 0;
    y: number = 0;
}

export class FloorModel {
    id: number = -1;
    name: string = "";
    controlPositions: ControlPositionModel[] = [];
    plan: string[] = [];
    planLeft: number = 0;
    planTop: number = 0;
    planWidth: number = 1;
    planHeight: number = 1;
}

export enum ControlType {
    Light = "Light",
    Blinds = "Blinds",
    Gate = "Gate",
    Meter = "Meter",
}

export enum ControlAction {
    LightOn = "LightOn",
    LightOff = "LightOff",
    BlindsUp = "BlindsUp",
    BlindsStepUp = "BlindsStepUp",
    BlindsStepDown = "BlindsStepDown",
    BlindsDown = "BlindsDown",
    GateOpen = "GateOpen",
    GateClose = "GateClose",
}

export class AppSettings {
    showEditor: boolean = false;
}

export interface LiveLogger {
    name: string;
    logLevel: string;
}

export interface IndicatorModel {
    id: string;
    name: string;
    kind: IndicatorKind;
    health: IndicatorHealth;
}

export enum IndicatorKind {
    System = "System",
    Guardian = "Guardian",
    StateMachine = "StateMachine",
    Communicator = "Communicator",
}

export enum IndicatorHealth {
    Offline = "Offline",
    Good = "Good",
    Moderate = "Moderate",
    Bad = "Bad",
}

export interface ToastHandler {
    show(message: string): void;
    showWithTitle(title: string, message: string): void;
}