import { Component, Emit } from 'vue-property-decorator';
import { ControlPositionModel } from '../../types';
import ControlComponent from './control';

@Component
export default class GateComponent extends ControlComponent {

    @Emit()
    gateMovementRequested(_controlPosition: ControlPositionModel) {
    }

    handleClick() {
        this.gateMovementRequested(this.control);
    }
}
