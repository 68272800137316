import './css/site.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/solid.min';
import '@fortawesome/fontawesome-free/js/regular.min';
import '@fortawesome/fontawesome-free/js/fontawesome.min';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import App from './components/app/app.vue';
import Home from './components/home/home.vue';

const GuardianData = () => import(/* webpackChunkName: "data" */ './components/guardian-data/guardian-data.vue');
const StateMachineConfig = () => import(/* webpackChunkName: "mgr" */ './components/state-machine-config/state-machine-config.vue');
const GuardianManager = () => import(/* webpackChunkName: "mgr" */ './components/guardian-manager/guardian-manager.vue');
const GuardianGroupConfig = () => import(/* webpackChunkName: "mgr" */ './components/guardian-manager/guardian-group-config.vue');
const CoordinatorConfig = () => import(/* webpackChunkName: "mgr" */  './components/coordinator-config/coordinator-config.vue');
const StateMachineManager = () => import(/* webpackChunkName: "mgr" */ './components/state-machine-manager/state-machine-manager.vue');
const LogLive = () => import(/* webpackChunkName: "log" */ './components/log-viewer/log-live.vue');
const LogViewer = () => import(/* webpackChunkName: "log" */ './components/log-viewer/log-viewer.vue');

Vue.use(VueRouter);

// Make FontAwesomeIcon component available on all other components
Vue.component("FontAwesomeIcon", FontAwesomeIcon)

const routes: RouteConfig[] = [
    { path: '/', component: Home },
    { path: '/guardian-manager', component: GuardianManager },
    { path: '/state-machine-manager', component: StateMachineManager },
    { path: '/coordinator-config', component: CoordinatorConfig },
    { path: '/guardian-config/:group?', component: GuardianGroupConfig, props: true },
    { path: '/log-live', component: LogLive },
    { path: '/log-viewer', component: LogViewer },
    { path: '/guardian-data', component: GuardianData },
    { path: '/state-machine-config/:stateMachineId', component: StateMachineConfig, props: true },
];

new Vue({
    router: new VueRouter({ mode: 'history', routes: routes }),
    render: h => h(App)
}).$mount('#app');
