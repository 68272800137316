import { Vue, Component, Inject, Emit } from 'vue-property-decorator';
import Api from '../../api';

@Component
export default class ModalAuthorizeComponent extends Vue {

    apiKey: string = "";

    @Inject() readonly api!: Api;

    @Emit()
    apiKeySet() {
        this.api.setKey(this.apiKey);
    }
}
