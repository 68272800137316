import { Vue, Component, Emit, Watch, Inject } from 'vue-property-decorator';
import { FloorModel, ControlPositionModel, ControlAction, ControlType, AppSettings, IndicatorModel, IndicatorHealth } from '../../types';
import FloorPlan from './floor-plan.vue';
import BuildingEditor from './building-editor.vue';
import ModelStore from '../../model-store';
import Api from '../../api';

@Component({
    components: {
        FloorPlan,
        BuildingEditor
    }
})
export default class BuildingComponent extends Vue {

    @Inject() readonly api!: Api;
    @Inject() readonly model!: ModelStore;
    @Inject() readonly appSettings!: AppSettings;

    static readonly SELECTED_FLOOR: string = "selectedFloor";

    localFloors: FloorModel[] = [];
    localControls: ControlPositionModel[] = [];

    selectedFloor: FloorModel | null = null;
    selectedBlindsInfo: ControlPositionModel | null = null;
    selectedGateInfo: ControlPositionModel | null = null;

    viewMode: BuildingViewMode = BuildingViewMode.Info;

    get isFloorView(): boolean {
        return this.viewMode === BuildingViewMode.Floor;
    }
    get isInfoView(): boolean {
        return this.viewMode === BuildingViewMode.Info;
    }
    get isEditorView(): boolean {
        return this.viewMode === BuildingViewMode.Editor;
    }

    get hasErrors(): boolean {
        return this.model.globalIndicator.health == IndicatorHealth.Bad;
    }
    get hasWarnings(): boolean {
        return this.model.globalIndicator.health == IndicatorHealth.Moderate;
    }

    get blindsValue(): number {
        if (this.selectedBlindsInfo) {
            return this.selectedBlindsInfo.value * 100;
        } else {
            return 0;
        }
    }

    get blindsHeightStyle(): string {
        return `height: ${this.blindsValue}%`;
    }

    @Emit()
    actionExecuted(_controlPosition: ControlPositionModel, _action: ControlAction) {
    }

    @Emit()
    controlMoved(_floor: FloorModel, _controlPosition: ControlPositionModel) {
    }

    @Emit()
    indicatorDeactivated(indicatorId: string) {
    }

    @Watch("model.floors")
    floorsChanged() {
        this.localFloors = this.model.floors;

        let floor: FloorModel | undefined;
        const floorName = localStorage.getItem(BuildingComponent.SELECTED_FLOOR);
        if (floorName) {
            floor = this.localFloors.filter(f => f.name === floorName)[0];
        }

        if (floor) {
            this.selectFloor(floor);
        }
    }

    mounted() {
        this.floorsChanged();
    }

    selectFloor(floor: FloorModel) {
        this.selectedFloor = floor;
        this.viewMode = BuildingViewMode.Floor;

        if (this.selectedFloor && this.selectedFloor.name) {
            localStorage.setItem(BuildingComponent.SELECTED_FLOOR, this.selectedFloor.name);
        }
    }

    selectInfo() {
        this.selectedFloor = null;
        this.viewMode = BuildingViewMode.Info;
        localStorage.removeItem(BuildingComponent.SELECTED_FLOOR);
    }

    selectEditor() {
        this.selectedFloor = null;
        this.viewMode = BuildingViewMode.Editor;
        localStorage.removeItem(BuildingComponent.SELECTED_FLOOR);
    }

    isSelectedFloor(floor: FloorModel): boolean {
        return this.isFloorView && (this.selectedFloor === floor);
    }

    areLightsOn(floor: FloorModel): boolean {
        return floor.controlPositions.filter(cp => cp.type == ControlType.Light && cp.isUp).length > 0;
    }

    handleControlMoved(controlPosition: ControlPositionModel) {
        if (this.selectedFloor) {
            this.controlMoved(this.selectedFloor, controlPosition);
        }
    }

    handleLightSwitched(control: ControlPositionModel, isOn: boolean) {
        this.actionExecuted(control, isOn ? ControlAction.LightOn : ControlAction.LightOff);
    }

    handleBlindsMovementRequested(control: ControlPositionModel) {
        if (this.selectedBlindsInfo === null) {
            this.selectedBlindsInfo = control;
        } else {
            this.selectedBlindsInfo = null;
        }
    }

    handleGateMovementRequested(control: ControlPositionModel) {
        if (this.selectedGateInfo === null) {
            this.selectedGateInfo = control;
        } else {
            this.selectedGateInfo = null;
        }
    }

    handleBlindsUp() {
        if (this.selectedBlindsInfo != null) {
            this.actionExecuted(this.selectedBlindsInfo, ControlAction.BlindsUp);
            this.selectedBlindsInfo = null;
        }
    }

    handleBlindsStepUp() {
        if (this.selectedBlindsInfo != null) {
            this.actionExecuted(this.selectedBlindsInfo, ControlAction.BlindsStepUp);
            this.selectedBlindsInfo = null;
        }
    }

    handleBlindsStepDown() {
        if (this.selectedBlindsInfo != null) {
            this.actionExecuted(this.selectedBlindsInfo, ControlAction.BlindsStepDown);
            this.selectedBlindsInfo = null;
        }
    }

    handleBlindsDown() {
        if (this.selectedBlindsInfo != null) {
            this.actionExecuted(this.selectedBlindsInfo, ControlAction.BlindsDown);
            this.selectedBlindsInfo = null;
        }
    }

    handleBlindsCancel() {
        this.selectedBlindsInfo = null;
    }

    handleGateOpen() {
        if (this.selectedGateInfo != null) {
            this.actionExecuted(this.selectedGateInfo, ControlAction.GateOpen);
            this.selectedGateInfo = null;
        }
    }

    handleGateClose() {
        if (this.selectedGateInfo != null) {
            this.actionExecuted(this.selectedGateInfo, ControlAction.GateClose);
            this.selectedGateInfo = null;
        }
    }

    handleGateCancel() {
        this.selectedGateInfo = null;
    }

    deactivateIndicator(indicator: IndicatorModel) {
        this.indicatorDeactivated(indicator.id);
    }
}

enum BuildingViewMode {
    Floor,
    Info,
    Editor
}
