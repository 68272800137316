import { Vue, Component, Provide, Watch } from 'vue-property-decorator';
import Api from '../../api';
import NavMenu from '../nav-menu/nav-menu.vue';
import ModalConfirm from '../modal-confirm/modal-confirm.vue';
import ModalAuthorize from '../modal-authorize/modal-authorize.vue';
import ModalConnection from '../modal-connection/modal-connection.vue';
import * as signalR from '@microsoft/signalr';
import ModelStore from '../../model-store';
import { AppSettings, ToastHandler } from '../../types';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';
import { Toast } from 'bootstrap';

let jquery: any = require('jquery');

@Component({
    components: {
        NavMenu,
        ModalConfirm,
        ModalAuthorize,
        ModalConnection
    }
})
export default class AppComponent extends Vue {

    @Provide('api') api: Api;
    @Provide('model') model: ModelStore;
    @Provide('appSettings') appSettings: AppSettings;
    @Provide('toastHandler') toastHandler: ToastHandler;

    showModalAuthorize: boolean = false;

    showModalApiError: boolean = false;
    apiErrorMessage: string = "";
    toastTitle: string | null = null;
    toastMessage: string = "";

    connection: signalR.HubConnection;

    constructor() {
        super();
        this.api =
            new Api({
                errorHandler: errorMessage => {
                    this.handleError(errorMessage);
                },
                authorizationHandler: () => {
                    this.handleAuthorization();
                }
            });

        this.model = new ModelStore();

        this.appSettings = new AppSettings();
        this.appSettings.showEditor = localStorage.getItem("showEditor") == "true";
        this.toastHandler = new ToastHandlerImpl(this);

        this.connection =
            new signalR.HubConnectionBuilder()
                .withUrl("/hub", { accessTokenFactory: () => this.api.getKey() })
                .withAutomaticReconnect(new RetryPolicy())
                .build();
        this.connection.on("UpdateGuardians", (mode, guardians) => this.model.updateGuardians(mode, guardians));
        this.connection.on("UpdateStateMachines", (mode, stateMachines) => this.model.updateStateMachines(mode, stateMachines));
        this.connection.on("UpdateCommunicators", (mode, communicators) => this.model.updateCommunicators(mode, communicators));
        this.connection.on("UpdateIndicators", (mode, globalIndicator, indicators) => this.model.updateIndicators(mode, globalIndicator, indicators));
        this.connection.on("UpdateControls", (mode, controls) => this.model.updateControls(mode, controls));
        this.connection.on("UpdateFloors", (mode, floors) => this.model.updateFloors(mode, floors));
        this.connection.on("LogMessage", (timestamp, logLevel, loggerName, message) => this.model.addLogMessage(timestamp, logLevel, loggerName, message));
        this.connection.onclose((error) => this.handleConnectionClose(error));
        this.connection.onreconnecting((error) => this.handleConnectionReconnecting(error));
        this.connection.onreconnected(() => this.handleConnectionReconnected());
        this.connection.start().catch(this.handleConnectionStartErrors);

        document.addEventListener(
            "visibilitychange",
            _ => {
                if (!document.hidden) {
                    this.connection.send("updateAll");
                }
            },
            false
        );
    }

    handleAuthorizationKeyUpdated() {
        window.location.reload();
    }

    @Watch("appSettings.showEditor")
    handleAppSettingsChanged() {
        localStorage.setItem("showEditor", this.appSettings.showEditor.toString());
    }

    showToast(title: string | null, message: string): void {
        this.toastTitle = title;
        this.toastMessage = message;
        const toastElem = this.$refs["toast"];
        if (toastElem instanceof Element) {
            const toast = new Toast(toastElem, { delay: 3000 });
            toast.show();
        }
    }

    private handleError(errorMessage: string) {
        console.error("API Error: " + errorMessage);
        if (!this.showModalApiError) {
            this.showModalApiError = true;
            this.apiErrorMessage = "API Error: " + errorMessage;
        }
    }

    private handleAuthorization() {
        if (!this.showModalAuthorize) {
            console.error("Authorization failed!");
            this.showModalAuthorize = true;
        }
    }

    private handleConnectionClose(error: Error | undefined) {
        this.handleConnectionError(new Error("Verbindung geschlossen"));
    }

    private handleConnectionReconnecting(error: Error | undefined) {
        this.handleConnectionError(new Error("Verbinden..."));
    }

    private handleConnectionReconnected() {
        this.api.connectionError = null;
    }

    private handleConnectionStartErrors(error: Error) {
        if (error instanceof FailedToNegotiateWithServerError) {
            this.showModalAuthorize = true;
        } else {
            this.handleConnectionError(error);
        }
    }

    private handleConnectionError(error: Error) {
        console.error("Connection error: ", error);
        if (error) {
            this.api.connectionError = error;
        } else {
            this.api.connectionError = new Error("Unbekannter Fehler");
        }
    }
}

class RetryPolicy implements signalR.IRetryPolicy {

    nextRetryDelayInMilliseconds(retryContext: signalR.RetryContext): number | null {
        return 3000;
    }
}

class ToastHandlerImpl implements ToastHandler {

    app: AppComponent;

    constructor(app: AppComponent) {
        this.app = app;
    }

    show(message: string) {
        this.app.showToast(null, message);
    }

    showWithTitle(title: string, message: string) {
        this.app.showToast(title, message);
    }
}