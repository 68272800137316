import { Vue, Component, Inject } from 'vue-property-decorator';
import { ControlAction, ControlPositionModel, FloorModel } from '../../types';
import Api from '../../api';
import Building from '../building/building.vue';

@Component({
    components: {
        Building
    }
})
export default class HomeComponent extends Vue {
    @Inject() readonly api!: Api;

    handleControlAction(controlPosition: ControlPositionModel, action: ControlAction) {
        this.api.call(`api/Building/ExecuteAction/${controlPosition.controlId}/${action}`);
    }

    handleControlMoved(floor: FloorModel, controlPosition: ControlPositionModel) {
        this.api.call(`api/Building/PositionControl/${floor.id}/${controlPosition.id}/${controlPosition.x}/${controlPosition.y}`);
    }

    handleIndicatorDeactivated(indicatorId: string) {
        this.api.call(`api/Building/DeactivateIndicator/${indicatorId}`);
    }
}
