import { Vue, Component, Prop, Inject, Emit } from 'vue-property-decorator';
import { AppSettings, ControlModel, ControlPositionModel } from '../../types';

@Component
export default class ControlComponent extends Vue {

    @Prop() control!: ControlPositionModel;
    @Inject() appSettings!: AppSettings;

    isPointerOver: boolean = false;
    deltaX: number = 0;
    deltaY: number = 0;
    svgInfo: SvgInfo | null = null;

    get isValid(): boolean {
        return this.control.isValid;
    }

    get isValueValid(): boolean {
        return this.control.isValueValid;
    }

    get isUp(): boolean {
        return this.control.isValid && this.control.isUp;
    }

    get isDown(): boolean {
        return this.control.isValid && this.control.isDown;
    }

    get x(): number {
        return this.control.x + this.deltaX;
    }

    get y(): number {
        return this.control.y + this.deltaY;
    }

    @Emit()
    controlMoved(_controlPosition: ControlPositionModel) {
    }

    protected handleClick() {
    }

    handlePointerDown(pointerEvent: PointerEvent) {
        if (this.appSettings.showEditor) {
            if (this.svgInfo === null) {
                const svgElement = this.$refs["svgElement"] as SVGGraphicsElement;
                if (svgElement && svgElement.ownerSVGElement) {
                    this.svgInfo = new SvgInfo(svgElement, svgElement.ownerSVGElement.createSVGPoint());
                }
            }

            if (this.svgInfo !== null) {
                this.svgInfo.matrix = this.svgInfo.element.getScreenCTM();
                if (this.svgInfo.matrix !== null) {
                    this.svgInfo.matrix = this.svgInfo.matrix.inverse();

                    this.svgInfo.point.x = pointerEvent.clientX;
                    this.svgInfo.point.y = pointerEvent.clientY;
                    const newPoint = this.svgInfo.point.matrixTransform(this.svgInfo.matrix);
                    this.svgInfo.origX = newPoint.x;
                    this.svgInfo.origY = newPoint.y;

                    this.svgInfo.element.setPointerCapture(pointerEvent.pointerId);
                }
            }
        }
    }

    handlePointerUp(pointerEvent: PointerEvent) {
        if (this.appSettings.showEditor) {
            this.endPointerMove(pointerEvent);
        } else {
            if (this.control.isValid) {
                this.handleClick();
            }
        }
    }

    handlePointerMove(pointerEvent: PointerEvent) {
        if (this.svgInfo !== null && this.svgInfo.matrix !== null) {
            this.svgInfo.point.x = pointerEvent.clientX;
            this.svgInfo.point.y = pointerEvent.clientY;
            const newPoint = this.svgInfo.point.matrixTransform(this.svgInfo.matrix);
            this.deltaX = newPoint.x - this.svgInfo.origX;
            this.deltaY = newPoint.y - this.svgInfo.origY;
        }
    }

    handlePointerOver() {
        this.isPointerOver = true;
    }

    handlePointerOut(pointerEvent: PointerEvent) {
        this.isPointerOver = false;
        this.endPointerMove(pointerEvent);
    }

    private endPointerMove(pointerEvent: PointerEvent) {
        if (this.svgInfo !== null) {
            if (this.svgInfo.matrix !== null) {
                this.control.x = Math.round(this.x * 1000) / 1000;
                this.control.y = Math.round(this.y * 1000) / 1000;

                this.svgInfo.element.releasePointerCapture(pointerEvent.pointerId);
                this.svgInfo.matrix = null;

                this.controlMoved(this.control);
            }
        }

        this.deltaX = 0;
        this.deltaY = 0;
    }
}

class SvgInfo {
    element: SVGGraphicsElement;
    point: SVGPoint;
    matrix: DOMMatrix | null = null;
    origX: number = 0;
    origY: number = 0;

    constructor(element: SVGGraphicsElement, point: SVGPoint) {
        this.element = element;
        this.point = point;
    }
}
