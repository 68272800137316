import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class ModalYesNoComponent extends Vue {

    @Prop() title!: string;
    @Prop() message!: string;

    @Emit()
    closed(_answerIsYes: boolean) {
    }
}
