import { Component, Emit } from 'vue-property-decorator';
import { ControlPositionModel } from '../../types';
import ControlComponent from './control';

@Component
export default class LightComponent extends ControlComponent {

    @Emit()
    lightSwitched(_controlPosition: ControlPositionModel, _doSwitchOn: boolean) {
    }

    handleClick() {
        this.lightSwitched(this.control, !this.isUp);
    }
}
