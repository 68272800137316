import { Vue, Component, Prop } from 'vue-property-decorator';
import { ControlPositionModel } from '../../types';
import ControlComponent from './control';

@Component
export default class MeterComponent extends ControlComponent {

    @Prop() unit!: string;
    @Prop() precision!: number;
}
