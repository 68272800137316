import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class ConfirmComponent extends Vue {

    @Prop() message!: string;

    @Emit()
    closed() {
    }
}
