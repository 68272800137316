import { Vue, Component, Prop, Emit, Inject } from 'vue-property-decorator';
import { AppSettings, ControlPositionModel, FloorModel } from '../../types';
import Light from './light.vue';
import Blinds from './blinds.vue';
import Gate from './gate.vue';
import Meter from './meter.vue';

@Component({
    components: {
        Light,
        Blinds,
        Gate,
        Meter
    }
})
export default class FloorPlanComponent extends Vue {
    @Prop() floor!: FloorModel;
    @Inject() appSettings!: AppSettings;

    get planSvg(): string {
        return this.floor.plan.join("");
    }

    get viewBox(): string {
        return `${this.floor.planLeft} ${this.floor.planTop} ${this.floor.planWidth} ${this.floor.planHeight}`;
    }

    get touchActionStyle(): string {
        return this.appSettings.showEditor ? "touch-action: pinch-zoom" : "";
    }

    @Emit()
    controlMoved(control: ControlPositionModel) {
        control.x = Math.max(this.floor.planLeft, Math.min(this.floor.planLeft + this.floor.planWidth, control.x));
        control.y = Math.max(this.floor.planTop, Math.min(this.floor.planTop + this.floor.planHeight, control.y));
    }

    @Emit()
    lightSwitched(_control: ControlPositionModel, _isOn: boolean) {
    }

    @Emit()
    blindsMovementRequested(_control: ControlPositionModel) {
    }

    @Emit()
    gateMovementRequested(_control: ControlPositionModel) {
    }
}
