import { Vue, Component, Inject } from 'vue-property-decorator';
import ModelStore from '../../model-store';
import { IndicatorHealth } from '../../types';

@Component
export default class NavMenuComponent extends Vue {
    @Inject() readonly model!: ModelStore;

    get hasErrors(): boolean {
        return this.model.globalIndicator.health == IndicatorHealth.Bad;
    }
    get hasWarnings(): boolean {
        return this.model.globalIndicator.health == IndicatorHealth.Moderate;
    }
}