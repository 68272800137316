import { Vue, Component, Prop, Emit, Watch, Inject } from 'vue-property-decorator';
import { FloorModel, ControlModel, ControlPositionModel, ToastHandler } from '../../types';
import ModelStore from '../../model-store';
import Api from '../../api';
import FloorPlan from './floor-plan.vue';
import ModalYesNo from '../modal-yesno/modal-yesno.vue';
import * as Utilities from '../../utilities';

@Component({
    components: {
        FloorPlan,
        ModalYesNo,
    }
})
export default class BuildingEditorComponent extends Vue {

    @Inject() readonly api!: Api;
    @Inject() readonly model!: ModelStore;
    @Inject() readonly toastHandler!: ToastHandler;

    localFloors: FloorModel[] = [];
    localControls: ControlModel[] = [];

    controlGuardian: number = -1;
    controlStateMachine: number = -1;
    selectedEditControl: ControlModel | null = null;
    selectedEditFloor: FloorModel | null = null;

    confirmTitle: string = "";
    confirmMessage: string = "";
    confirmShow: boolean = false;
    private confirmResolve: (() => void) | null = null;

    @Watch("model.floors")
    floorsChanged() {
        this.localFloors = this.model.floors;

        if (this.selectedEditFloor !== null) {
            const floorId = this.selectedEditFloor.id;
            this.selectedEditFloor = this.localFloors.filter(f => f.id === floorId)[0];
        }
    }

    mounted() {
        this.floorsChanged();
    }

    isSelectedEditControl(control: ControlModel) {
        return this.selectedEditControl !== null && this.selectedEditControl.id === control.id;
    }

    isSelectedEditFloor(floor: FloorModel) {
        return this.selectedEditFloor !== null && this.selectedEditFloor.id === floor.id;
    }

    handleAddLights() {
        this.api.call(`api/Building/AddLights/${this.controlGuardian}`)
            .then(response => this.handleActionResult(response, "Der Sensor enthält keine Leuchten oder wurde schon verwendet."));
    }

    handleAddBlinds() {
        this.api.call(`api/Building/AddBlinds/${this.controlGuardian}`)
            .then(response => this.handleActionResult(response, "Der Sensor enthält keine Jalousien oder wurde schon verwendet."));
    }

    handleAddMeters() {
        this.api.call(`api/Building/AddMeters/${this.controlGuardian}`)
            .then(response => this.handleActionResult(response, "Der Sensor enthält keine Messwerte oder wurde schon verwendet."));
    }

    handleAddGate() {
        this.api.call(`api/Building/AddGate/${this.controlStateMachine}`)
            .then(response => this.handleActionResult(response, "Der Ablauf ist nicht geeignet für ein Tor-Steuerelement oder wurde schon verwendet."));
    }

    handleDeleteControl(control: ControlModel) {
        this.confirm("Steuerelement löschen", `Wollen Sie "${control.name}" wirklich löschen?`)
            .then(() => {
                this.selectedEditControl = null;
                this.api.call(`api/Building/DeleteControl/${control.id}`)
                    .then(this.handleActionResult);
            });
    }

    handleAddFloor() {
        this.api.call(`api/Building/AddFloor`).then(this.handleActionResult);
    }

    handleUpdateFloor(floor: FloorModel) {
        this.api.call(`api/Building/UpdateFloor`,
            {
                method: "POST",
                body:
                    JSON.stringify(
                        {
                            'id': floor.id,
                            'name': floor.name,
                            'planLeft': floor.planLeft,
                            'planTop': floor.planTop,
                            'planWidth': floor.planWidth,
                            'planHeight': floor.planHeight,
                        }
                    ),
                headers: { "content-type": "application/json" }
            }).then(this.handleActionResult);
    }

    handleDeleteFloor(floor: FloorModel) {
        this.confirm("Stockwerk löschen", `Wollen Sie "${floor.name}" wirklich löschen?`)
            .then(() => {
                this.selectedEditFloor = null;
                this.api.call(`api/Building/DeleteFloor/${floor.id}`).then(this.handleActionResult);
            });
    }

    handleAddControlToFloor() {
        if (this.selectedEditControl !== null && this.selectedEditFloor !== null) {
            this.api.call(`api/Building/AddControlToFloor/${this.selectedEditFloor.id}/${this.selectedEditControl.id}`).then(this.handleActionResult);
        }
    }

    handleRemoveControlPositionFromFloor(floor: FloorModel, controlPosition: ControlPositionModel) {
        this.confirm("Steuerelement löschen", `Wollen Sie "${controlPosition.name}" auf Stockwerk "${floor.name}" wirklich löschen?`)
            .then(() => this.api.call(`api/Building/RemoveControlFromFloor/${floor.id}/${controlPosition.id}`)).then(this.handleActionResult);
    }

    handleMovePositionControl(floor: FloorModel, controlPosition: ControlPositionModel) {
        controlPosition.x = Math.max(floor.planLeft, Math.min(floor.planLeft + floor.planWidth, controlPosition.x));
        controlPosition.y = Math.max(floor.planTop, Math.min(floor.planTop + floor.planHeight, controlPosition.y));
        this.api.call(`api/Building/PositionControl/${floor.id}/${controlPosition.id}/${controlPosition.x}/${controlPosition.y}`).then(this.handleActionResult);
    }

    handlePlanUpload(event: any) {
        const files = (event.target as HTMLInputElement).files;
        if (this.selectedEditFloor !== null && files && files.length > 0) {

            const form = new FormData();
            form.set("floorId", this.selectedEditFloor.id.toString());
            form.set("file", files[0]);

            this.api.call(`api/Building/UploadFloorPlan`,
                { method: "POST", body: form }).then(this.handleActionResult);
        }
    }

    handlePlanDownload() {
        if (this.selectedEditFloor !== null) {
            this.api
                .call(`/api/Building/DownloadFloorPlan/${this.selectedEditFloor.id}`)
                .then(response => response.blob())
                .then(blob => Utilities.downloadBlob(blob,
                    `Floor_${this.selectedEditFloor?.id ?? 0}.svg`));
        }
    }

    handleControlMoved(control: ControlPositionModel) {
        if (this.selectedEditFloor) {
            this.handleMovePositionControl(this.selectedEditFloor, control);
        }
    }

    private confirm(title: string, message: string): Promise<void> {
        return new Promise((resolve, _reject) => {
            this.confirmResolve = resolve;
            this.confirmTitle = title;
            this.confirmMessage = message;
            this.confirmShow = true;
        });
    }

    handleConfirmClosed(answerIsYes: boolean) {
        this.confirmShow = false;
        if (answerIsYes) {
            if (this.confirmResolve) {
                this.confirmResolve();
            }
        }

        this.confirmResolve = null;
    }

    private handleActionResult(response: Response, message?: string) {
        if (response && response.status !== 200) {
            this.toastHandler.showWithTitle("Aktion fehlgeschlagen.", message ?? "Es ist ein Fehler aufgetreten.")
        }
    }
}
