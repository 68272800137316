import { GuardianModel, StateMachineStatus, CommunicatorStateModel, CoordinatorUpdateMode, FloorModel, ControlModel, IndicatorModel, IndicatorHealth, IndicatorKind } from './types';

export default class ModelStore {

    guardians: GuardianModel[] = [];
    stateMachines: StateMachineStatus[] = [];
    communicators: CommunicatorStateModel[] = [];
    controls: ControlModel[] = [];
    floors: FloorModel[] = [];
    globalIndicator: IndicatorModel = new IndicatorModelInstance();
    indicators: IndicatorModel[] = [];
    logMessages: LogMessage[] = [];

    public updateGuardians(updateMode: CoordinatorUpdateMode, newGuardians: GuardianModel[]) {
        if (updateMode === CoordinatorUpdateMode.Full) {
            this.guardians = newGuardians;
        } else {
            for (const newGuardian of newGuardians) {
                const idx = this.guardians.findIndex(g => g.id === newGuardian.id);
                if (idx >= 0) {
                    this.guardians.splice(idx, 1, newGuardian);
                } else {
                    this.guardians.push(newGuardian);
                }
            }
        }

        this.guardians.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
    }

    public updateStateMachines(updateMode: CoordinatorUpdateMode, newStateMachines: StateMachineStatus[]) {
        if (updateMode === CoordinatorUpdateMode.Full) {
            this.stateMachines = newStateMachines;
        } else {
            for (const newStateMachine of newStateMachines) {
                const idx = this.stateMachines.findIndex(sm => sm.id === newStateMachine.id);
                if (idx >= 0) {
                    this.stateMachines.splice(idx, 1, newStateMachine);
                } else {
                    this.stateMachines.push(newStateMachine);
                }
            }
        }

        this.stateMachines.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
    }

    public updateCommunicators(updateMode: CoordinatorUpdateMode, newCommunicators: CommunicatorStateModel[]) {
        if (updateMode === CoordinatorUpdateMode.Full) {
            this.communicators = newCommunicators;
        } else {
            for (const newCommunicator of newCommunicators) {
                const idx = this.communicators.findIndex(c => c.name === newCommunicator.name);
                if (idx >= 0) {
                    this.communicators.splice(idx, 1, newCommunicator);
                } else {
                    this.communicators.push(newCommunicator);
                }
            }
        }

        this.communicators.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
    }

    public updateControls(updateMode: CoordinatorUpdateMode, newControls: ControlModel[]) {
        if (updateMode === CoordinatorUpdateMode.Full) {
            this.controls = newControls;
        } else {
            for (const newControl of newControls) {
                const idx = this.controls.findIndex(c => c.id === newControl.id);
                if (idx >= 0) {
                    this.controls.splice(idx, 1, newControl);
                } else {
                    this.controls.push(newControl);
                }
            }
        }

        this.controls.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
    }

    public updateFloors(updateMode: CoordinatorUpdateMode, newFloors: FloorModel[]) {
        if (updateMode === CoordinatorUpdateMode.Full) {
            this.floors = newFloors;
        } else {
            for (const newFloor of newFloors) {
                const idx = this.floors.findIndex(c => c.id === newFloor.id);
                if (idx >= 0) {
                    this.floors.splice(idx, 1, newFloor);
                } else {
                    this.floors.push(newFloor);
                }
            }
        }
    }

    public updateIndicators(updateMode: CoordinatorUpdateMode, globalIndicator: IndicatorModel, newIndicators: IndicatorModel[]) {
        this.globalIndicator = globalIndicator;
        if (updateMode === CoordinatorUpdateMode.Full) {
            this.indicators = newIndicators;
        } else {
            for (const newIndicator of newIndicators) {
                const idx = this.indicators.findIndex(c => c.id === newIndicator.id);
                if (idx >= 0) {
                    this.indicators.splice(idx, 1, newIndicator);
                } else {
                    this.indicators.push(newIndicator);
                }
            }
        }
    }

    public addLogMessage(timestamp: string, logLevel: string, loggerName: string, message: string) {
        this.logMessages.push(new LogMessage(new Date(timestamp), logLevel, loggerName, message));
    }
}

export class LogMessage {

    timestamp: Date;
    logLevel: string;
    loggerName: string;
    message: string;

    constructor(timestamp: Date, logLevel: string, loggerName: string, message: string) {
        this.timestamp = timestamp;
        this.logLevel = logLevel;
        this.loggerName = loggerName;
        this.message = message;
    }
}

class IndicatorModelInstance implements IndicatorModel {
    id: string = "SYS";
    name: string = "Global";
    kind: IndicatorKind = IndicatorKind.System;
    health: IndicatorHealth = IndicatorHealth.Offline;
}