import * as CryptoJS from 'crypto-js';

export default class Api {

    private apiKey: string = "";
    private errorHandler: (message: string) => void;
    private authorizationHandler: () => void;

    connectionError: Error | null = null;

    constructor(options: { errorHandler: (message: string) => void; authorizationHandler: () => void }) {
        this.errorHandler = options.errorHandler;
        this.authorizationHandler = options.authorizationHandler;

        const apiKeyItem = localStorage.getItem("apiKey");
        this.apiKey = apiKeyItem ? apiKeyItem : "";
    }

    public call(input: RequestInfo, init?: RequestInit): Promise<Response> {
        return fetch(input, this.getFetchInit(init))
            .then(response => {
                if (!response.ok) {


                    if (response.status === 401) {
                        this.authorizationHandler();
                    } else {
                        throw new Error(response.status + " " + response.url);
                    }
                }

                return response;
            })
            .catch(response => {
                this.errorHandler(response);
                return response;
            });
    }

    public fetch<TData>(input: RequestInfo, init?: RequestInit): Promise<TData> {

        return fetch(input, this.getFetchInit(init))
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        this.authorizationHandler();
                    } else {
                        throw new Error(response.status + " " + response.url);
                    }
                }

                return response;
            })
            .then(response => response.json() as Promise<TData>)
            .catch(reason => {
                console.error(reason);
                this.errorHandler(reason);
                return reason;
            });
    }

    public getKey(): string {
        return this.apiKey;
    }

    public setKey(apiKey: string) {
        this.apiKey =
            CryptoJS.enc.Base64.stringify(
                CryptoJS.PBKDF2(apiKey, CryptoJS.lib.WordArray.create([], 16), { keySize: 256 / 32, iterations: 382, hasher: CryptoJS.algo.SHA256 }));
        localStorage.setItem("apiKey", this.apiKey);
    }

    private getFetchInit(init?: RequestInit): RequestInit {

        const apiKeyHeaders = new Headers();
        apiKeyHeaders.set("Authorization", this.apiKey);

        if (init) {
            const fetchInit: RequestInit = init;
            if (fetchInit.headers) {
                const headers = new Headers(fetchInit.headers);
                headers.set("Authorization", this.apiKey);
                fetchInit.headers = headers;
            } else {
                fetchInit.headers = apiKeyHeaders;
            }

            return fetchInit;
        } else {
            return { headers: apiKeyHeaders };
        }
    }
}